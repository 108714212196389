import React from "react";
import styled from 'styled-components'

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (

<FormWrap className="mb-5">
<form onSubmit={this.submitForm}
        action="https://formspree.io/mrgaprao"
        method="POST">

<div class="form-group">
    <input type="text" className="form-control" name="text" id="exampleInputPassword1" placeholder="Name" required/>
  </div>
  <div class="form-group">
    <input type="email" className="form-control" name="email" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder=" email" required/>
  </div>
 

  <div class="form-group">
    <input type="txet" className="form-control" name="phone" id="exampleInputPassword1" placeholder="phone Number" required/>
  </div>
  <div class="form-group">
    <textarea className="form-control" name="message" id="exampleFormControlTextarea1" rows="3" placeholder="text" required></textarea>
  </div>
 
  {status === "SUCCESS" ? <div class="alert alert-success" role="alert">
  message sent succefully !
</div> :  <button type="submit"  className="cfb">SEND</button>}
        {status === "ERROR" && <div class="alert alert-danger" role="alert">
        Ooops! There was an error.
</div>}
</form>
</FormWrap>

    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}



const FormWrap = styled.div`

input{

    border-radius:25px;
    height:45px;
}

.cfb{

    cursor: pointer;
    display:block;
    border:2px solid orange;
    border-radius:25px;
    background-color:transparent;
    padding:8px 30px;
    width:100%;
    color:white;
    font-size:16px;
    font-weight:bold;
    font-family:var(--TextFont);
    
}

@media(max-width:960px){


  margin-top:2rem;
}

`
