import React from 'react';
import styled from 'styled-components'
import Heading from '../../../global/heading'
import Cbg from '../../../images/contactbg.jpg'
import { FaHome, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import { MdCallEnd, MdMailOutline } from "react-icons/md";
import CForm  from './cform'                                                                                  
const Contact = (props) => {

let cdata= [{

    icon:<FaHome/>,
    content:`Office: #60, 3rd Floor, Panchamaveda, Ullal Main Rd, 3rd Block, Maruthi Nagar, Sir M Vishveswaraya Layout, Jnana Ganga Nagar, Bengaluru, Karnataka 560110`
},
{

    icon:<MdCallEnd/>,
    content:`080 2324 1662 `,
    mail:"#contact"
},
{

    icon:<MdMailOutline/>,
    content:`svasthya@svasthyahealth.com `,
    mail:"mailto:svasthya@svasthyahealth.com"
}]

let socialdata = [

{

    icon:<FaFacebookF/>,
    link:"https://www.facebook.com/Svasthya-1545290395600557/"
},
{

    icon:<FaLinkedinIn/>,
    link:"https://www.linkedin.com/company/svasthyahealth/"
},

]

    return (
        
        <ContactWrap className="container-fluid py-5" id="contact" style={{backgroundImage:`url('${Cbg}')`}}>

<div className="container">
    <div className="row">
<div className="col-sm-6 py-5">
<Heading bgcolor="bg-light" color="text-white" tcolor="#f5f5f5" title="Contact Us" hcolor="text-light"/>

</div>
<div className="col-sm-6"></div>



        <div className="col-sm-6">


        <h3>
        HAVE A QUESTION? GIVE US A CALL OR FILL OUT THE CONTACT FORM – WE'D LOVE TO HEAR FROM YOU 
        </h3>

        <ul className="p-0">

    {

        cdata.map((item, index)=>{

        return <li key={index}><span className="mail" >{item.icon}</span> <a href={item.mail} target="_top">{item.content}</a></li>
        })
    


    }
</ul>

<div className="socialContainer mt-5">
{


socialdata.map((sicon, index)=>{


return(<>

<div className="social ">
    
     <a key={index} href={sicon.link} target="new_tab" >{sicon.icon}</a>

     </div>

</>) 

})
    
}
</div>
        </div>
        <div className="col-sm-6">

<CForm/>

        </div>

    </div>
</div>


        </ContactWrap>
    );
};

const ContactWrap = styled.div`
a{

text-decoration:none !important;
color:white !important;
}

.mail{



svg{

    transform:translateY(3px);
}

} 

background-size:cover;
position: relative;
background-position:center center;
 &::before{
position:absolute;
content:"";
top:0;
left:0;
    width:100%;
    height:100%;
    z-index:0;
    background: linear-gradient(14deg, rgba(0,0,0,0.9332107843137255) 0%, rgba(0,0,0,0.8575805322128851) 100%);
 }

 h3{

    margin:2rem 0;
    color:white;
    font-family:var(--HeadingFont);
    line-height:1.5;
    font-weight:700;
    font-size:22px;
    letter-spacing:0.8px;

 }

 .social{

    display:inline;
border:1px solid white;
border-radius:50%;
padding:6px 8px;
margin:10px;
opacity:0.4;
cursor: pointer;
transition:0.5s ease-in-out;
&:hover{

    opacity:1;

}
svg{

    color:white;
    font-size:16px;
}
 }

 ul{

    li{

        list-style:none;
        color:white;
        font-size:16px;
        margin:8px 0px;
        span{
        font-size:18px;

            padding:0 10px;
        }
    }
 }

`
export default Contact;