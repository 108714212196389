import React from 'react'
import styled from 'styled-components'
import team3 from '../../../images/teamimg/1.jpg'
import team1 from '../../../images/teamimg/2.jpg'
import team2 from '../../../images/teamimg/3.jpg'
import Header from '../../../global/heading'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';

export default function Team() {


let teamdata = [{

    name:"Avinash Babu M",
    status:"Founder & CEO",
    imge:team2,
    facbk:"",
    linkedin:"https://www.linkedin.com/in/avinash-jois-a524528/",

    content:`Brings 17 years of experience in building product development teams to cater to multiple geographies and verticals viz., CE, Medical Assistive, IOT, industrial and defense electronics. One of the early members at Mistral Solutions, an embedded product design house. Instrumental in building the product engineering services vertical.`

},
{

    name:"Prasad Achar",
    status:"Co-Founder & CTO",
    imge:team1,
    facbk:"",
    linkedin:" https://www.linkedin.com/in/prasad-achar-p-67137b6/",


    content:`Brings 18 years of experience in embedded product development. He has designed systems for wireless camera surveillance, in-flight entertainment systems, server blades and ultrasound scanners. Has extensive experience in FDA certification for medical devices. Earlier played team lead roles in engineering delivery at Mistral Solutions and Philips.`

},
{

    name:"Suresha N S",
    status:"Product Architect",
    imge:team3,
    facbk:"",
    linkedin:"https://www.linkedin.com/in/suresha-n-s-aa48856b/",

    content:`12 years of experience in the wireless sensor systems on BT,WiFi,GSM etc.Has designed systems for monitoring body vitals and industrial sensors. Brings in experience in power management & low power designs.Previously worked as Module lead at Mistral Solutions Pvt Ltd.`

}]







    return (
        <TeamWrap className="container py-5 " id="team">


<Header title="Our Team"/>


<div className="row py-3">

{

teamdata.map((item, index)=>{


return(<>


    <div className="col-sm-4 my-sm-0 my-2">          
<div className="membre" key={index}>
            <img src={item.imge} alt="teamimage" />
           
            <div className="info">
               <h5 className="name">{item.name}</h5>
               <p className="job">{item.status}</p>
            </div>

           <div className="overly top">
               <div className="middle">
<h5 class="name">{item.name}</h5>
            <p className="job">{item.status}</p>
            <p className="text">{item.content}
            </p>
               
            <div className="social-icones">
            
                <a href={item.linkedin} target="new_tab" className="icon">
                    <FaLinkedinIn/>
                </a>
            
            </div>
        </div>
        </div>
       </div>


       </div>

</>)


})
}

</div>





        </TeamWrap>
    )
}

const TeamWrap = styled.div`

img{
    width: 100%;
    height: auto;
}
p{
    color:#fff;
}

.middle{
   top:50%;
   transform: translateY(-50%);
   position: absolute;
}

.wrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px;
    padding: 50px;
}

.membre{
    box-shadow: 0px 2px 6px 0px #ccc;
    position: relative;
    overflow: hidden;
    transition:  all 0.5s ease-in;
    .info{
        background-color:rgba(0,0,0,0.5);
        padding: 20px 10px;
        position: absolute;
        bottom: 0;
        width: 100%;
        transition:  all 0.5s ease-in;    }
    .name{
        color:#fff;
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .job{
        color:#fff;
        font-size: 18px;
        font-weight: 600;
    }
    .text{
         margin: 15px 10px 15px 0px;
         font-size: 14px;
    }

    .overly{
        height: 0; 
        background-color:rgba(0,0,0,0.9);
        padding: 0;
        position: absolute; 
        width: 100%;
        display: block; 
        transition:  all 0.5s ease-in;    }

   
    .top{
        top:-100%;
        left:0;
         
    }

    .bottom{
        bottom:-100%;
        left:0;
        height: 100%;

    }

    .left{
        top: 0;
        right: -100%;
        height: 100%;
    }

    
    .social-icones{
        display: inline-flex;
        position:relative;
        .icon{
            width:40px;
            height: 40px;
            line-height: 40px;
            background-color: #fff;
            display: block;
            text-align: center;
            margin-right: 5px;
            color:#000;
        }   
    }

    .to-top{
        bottom: 0; 
        transition:  all 0.5s ease-in;    }
    &:hover{
        .overly{
            height: 100%;
            padding: 20px 10px; 
            
        }
        .info{
            position: absolute;
            bottom:-80px;
            padding: 0;
            transition:  all 0.5s ease-in;        }
        .top{
            top:0;
            bottom: 0px;
        }
        .bottom{
            bottom: 0;
        }

        .left{
            right: 0;
        } 
        .to-top{
            bottom:100% !important; 
            transition:  all 0.5s ease-in;        }

     }
}
@media screen and (max-width:868px){
  
}

@media screen and (max-width:600px){
 
}



`