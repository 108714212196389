import React from 'react';
import styled from 'styled-components'
import Heading from '../../../global/heading'
import bg from '../../../images/bg2.jpg'
import HandAnim from './hand'


const Specban = (props) => {


let specdata1 = [{

    title:"Accurate time coordinated bio vitals",
},
{

    title:`Paradigm shift to continuum care 
     `,
    
    
},{

    title:` Real time locationing
    `,

    
},{

    title:` Actionable data
    `,
 
}]


let specdata2 = [{

    title:` Patients context assessment`,
},
{

    title:`Scalable pay per use model

    `,
    
},{

    title:`No test administration
        `,
  
}]



    return (
         
        <SpecWrap className="container-fluid  "   style={{backgroundImage:`url(${bg})`}}>

            <div className="container">

<div className="row">
    <div className="col-sm-3 pt-5">
<Heading bgcolor="bg-light" color="text-white" hcolor="text-white" title="USP"/>


    </div>
    <div className="col-sm-3 text-right pt-5" style={{borderRight:"1px dotted white"}}>
{

specdata1.map((item, index)=>{

 return <div className="specdata mt-4" key={index}>

<h4>{item.title}</h4>
</div>

})
}
      

    </div>
    <div className="col-sm-3 pt-5">

{

specdata2.map((item, index)=>{

 return <div className="specdata mt-4" key={index}>

<h4>{item.title}</h4>
</div>

})
}

    </div>

    <div className="col-sm-3">

    <HandAnim/>

    </div>
</div>


            </div>

        </SpecWrap>
    );
};


const SpecWrap = styled.div`

position:relative;
overflow:hidden;
background-size:cover;
color:white;
font-family:var(--HeadingFont);

h6{

    font-weight:bold;
    font-size:13px;
    letter-spacing:1px;

}

p{
     font-size:14px;
}
&::before{

    position:absolute;
    top:0;
    left:0;
    content:"";
    width:100%;
    height:100%;
    background-color:rgba(34, 156, 251, 0.78);
    z-index:0;
    
}



`
export default Specban;
