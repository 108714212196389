import React from 'react';
import Heading from '../../../global/heading';
import styled from 'styled-components'

import healpimg from '../../../images/health presentation-01.jpg'
const AboutSec = (props) => {
    return (
        
        <AboutWrap className="container mt-5 " >

            <div className="row">
                <div className="col-sm-6 mt-5">
                    <Heading title="Health Preservation"/>
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-12 py-4">
         <img src={healpimg} alt="dfdf" className="d-block mx-auto p-1 p-sm-5" width="85%" />
                </div>
              
            
            </div>


        </AboutWrap>
    );
};

const AboutWrap = styled.div`

p{
    font-family:var(--HeadingFont);
    line-height:2;
    font-size:15px;
}
h5{

     font-family:var(--HeadingFont);
    line-height:1.7;
}


`
export default AboutSec;