import React from 'react';
import styled from 'styled-components'
import Heading from '../../../global/heading';
import bg from '../../../images/bggal.jpeg'
import GallerySection from '../gallerySec/gallery'

const GalleryBanner = (props) => {
    return (
        
        <GalleryBannerWrap className="container-fluid p-0 " id="gallery" style={{zIndex:22, backgroundImage:`url('${bg}')`}} >





<div className="container insidepg">
                <div className="row">
                    {/* <div className="col-sm-2"> </div> */}
<div className="col-sm-12">
    
<Heading hcolor="text-white" color="text-white" tcolor="#f5f5f5" title="Watch our Demo Video " />

<GallerySection/>
    
    
     </div>
{/* <div className="col-sm-2"> </div> */}
                    
                </div>
            </div>




          


        </GalleryBannerWrap>
    );
};

const GalleryBannerWrap = styled.div`

background-size:cover;
position: relative;
background-attachment:fixed;

.insidepg{

    padding:5rem 0;
    position: inherit;
    z-index:22;
}

&::before{

width:100%;
position:absolute;
content:"";
height:100%;
background-color:rgba(0,0,0,0.8);
z-index:1;


}




`
export default GalleryBanner;