import React from 'react';
import styled from 'styled-components';

import img1 from '../../../images/blogimages/iot.jpg'
import img2 from '../../../images/blogimages/iot2.jpg'
import img3 from '../../../images/blogimages/iotpw.jpg'
import img4 from '../../../images/blogimages/mqtt2.jpg'


const Blogs = (props) => {

let blogdata = [{
    title:"MQTT Implementation techniques for IOT devices",
    text:"SEPTEMBER 18, 2017 BY TANTRAGYAAN",
    bg:img4,
    path:"https://tantragyaansolutions.com/mqtt-implementation-techniques-for-iot-devices/"
},
{
    title:"Power Management in IOT devices",
    text:"AUGUST 1, 2017 BY TANTRAGYAAN",
    bg:img3,
    path:"https://tantragyaansolutions.com/power-management-in-iot-devices/"

},
{
    title:"MQTT as a protocol solution to IOT",
    text:"AUGUST 26, 2017 BY TANTRAGYAAN",
    bg:img2,
    path:"https://tantragyaansolutions.com/mqtt-as-a-protocol-solution-to-iot/"

},
{
    title:"IOT Healthcare – “Sick Care to Wellness care",
    text:"JULY 19, 2017 BY TANTRAGYAAN",
    bg:img1,
    path:"https://tantragyaansolutions.com/iot-healthcare-sick-care-to-wellness-care/"

}]


    return (
        
        <BlogWrap className="container-fluid" id="blog">


    <div className="row">


        {

            blogdata.map((item, index)=>{

return <>


<div className="col-sm-6 bgc" style={{backgroundImage:`url(${item.bg})`}} key={index}>
    <div className="contentblog ">
    <a href={item.path} target="new_tab" style={{textDecoration:"none"}}>
 
         <h3>{item.title}</h3>
            <p>{item.text}</p>
            </a>
        </div>   

           


</div>


</>

            })
        }
     
    </div>


        </BlogWrap>
    );
};

const BlogWrap = styled.div`

.bgc{
background-size:cover;
background-position:center;
position: relative;
cursor: pointer;
&::after{

background-color:rgba(0,0,0,0.6);
content:"";
position:absolute;
width:100%;
height:100%;
z-index:1;
top:0;
left:0;


}

&:hover{
    transition:0.2s ease-in-out;

    p{

        color:white;
    }
}
&:hover::after{


    background: linear-gradient(239deg, rgba(60,191,236,0.87718837535014) 0%, rgba(38,139,199,0.8575805322128851) 100%);


}


}


.contentblog{
    box-sizing:border-box;

    padding:5rem 7rem;
    z-index:2 !important;
    position: inherit;
    h3{

        font-size:20px;
        color:white;
        font-family:var(--HeadingFont);
    }

    p{

        font-size:11px;
        color:skyblue;
        line-height:2;
        font-weight:bold;
        letter-spacing:0.5px;

    }
}

@media(max-width:960px){

.contentblog{
    
    padding:1.5rem 1rem;  

    h3{

font-size:16px;

}
}
}
`
export default Blogs;