import React from 'react';
import {FaNotesMedical, FaFileMedicalAlt, FaDownload, FaHistory  } from "react-icons/fa";
import {GoGraph  } from "react-icons/go";

import {  GiHealthDecrease, GiHeartBeats } from 'react-icons/gi';
import { FiAlertTriangle,   FiSunrise } from 'react-icons/fi';
 import { MdLocationOn } from 'react-icons/md';
import styled from 'styled-components'
const FeatureCont2 = (props) => {


    let FeatureData = [
        {
    
            title:`Health collaboration
            platform for patient,
            doctors and family
            `,
            icon:<GiHealthDecrease/>,
    
        },
        {
    
            title:`Accurate time coordinated bio vitals
            `,
            icon:<FaNotesMedical/>,
    
        },
        {
    
            title:`Secure downstream access
            through mobile and web
            application
            `,
            icon:<FaDownload/>,
    
        },
        {
    
            title:`Patient Context Assessment
            `,
            icon:<GiHeartBeats/>,
    
        },
        {
    
            title:`Doctor’s dashboard to
            personalize health
            monitoring and alerts
            `,
            icon:<GoGraph/>,
    
        },
        {
    
            title:`Live and history
            view of patient data
            `,
            icon:<FaHistory/>,
    
        },
        ,
        {
    
            title:`Alerts through SMS, email
            and application notifications
            `,
            icon:<FiAlertTriangle/>,
    
        },
        {
    
            title:`User locationing through GPS
            for emergency care coordination
            `,
            icon:<MdLocationOn/>,
    
        }
    ]



     

    return (
        
        <FeaturSec2Wrap>

            <div className="row">


 {
       FeatureData.map((item, index)=>{
return(<>


  <div className="col-sm-3 my-4 iconSec" key={index}>


    <div className=" iconsvgcnt">{item.icon}</div>
  <p id="pco">{item.title}</p>


   
  </div>


</>)



       }) 

     

       
    }

</div>

        </FeaturSec2Wrap>
    );
};

const FeaturSec2Wrap = styled.div`

.iconSec{

    border-right:0.5px dotted grey;
}
.iconsvgcnt{

    font-size:32px;
    padding:15px;
    border-radius:50%;
    border:6px solid #f5f5f5;

    width:70px;
    height:70px;
    margin:auto;
    position:relative;

    svg{

        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        color:grey;
        font-weight:200;
    }
}

#pco{
    font-size:12px;
    text-align:center;
    color:#111111;
    font-weight:bold;
    padding:10px;
}

`
export default FeatureCont2;