import React from 'react';
import styled from 'styled-components'
import bg from '../../../images/bg.jpg'
import Loadable from 'react-loadable';



const InsideSec = Loadable({
    loader: () => import('./insideComponent'),
    loading: 'loading.....',
  });



const Paralax = (props) => {
    return (
        
        <ParalaxWrap className="container-fluid p-0" style={{backgroundImage:`url(${bg})`}}>


    <div className="insideSec">

<InsideSec/>

    </div>


        </ParalaxWrap>
    );
};

const ParalaxWrap = styled.div`

background-size:cover;


.insideSec{
    width:100%;
    height:100%;
    background-color:#000000c9;
    color:white;
}

@media(max-width:960px){



}

`
export default Paralax;