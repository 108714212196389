import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ParallaxProvider } from 'react-scroll-parallax';
import ParalaxSec from '../components/HomeSec/paralaxSec/paralax'
import GallerySec from '../components/HomeSec/gallerySec/galleryBaner'
import SpecSec from '../components/HomeSec/specSec/specban'
import AboutSec from '../components/HomeSec/AboutSec/about'
import ContactSec from '../components/HomeSec/contactSec/contactBan'
import Blogs from '../components/HomeSec/blog/blogs'
import Loadable from 'react-loadable';
import Team from '../components/HomeSec/AboutSec/team'

import Loading from '../global/loading'


import WatchFeatureSec from '../components/HomeSec/featureSec/watchslide';


const Slider = Loadable({
  loader: () => import('../components/HomeSec/bootstrapSlider/bslider'),
  loading: Loading,
});


const ClientSec = Loadable({
  loader: () => import('../components/HomeSec/clientSec/client'),
  loading: 'loading.....',
});

const GalleryImg = Loadable({
  loader: () => import('../components/HomeSec/NewpopupGallery/popupgallery'),
  loading: 'loading.....',
});


const HelpSec = Loadable({
  loader: () => import('../components/HomeSec/howitshelp/helpmain'),
  loading: 'loading.....',
});


const IndexPage = () => (
  <Layout>
  <SEO title="Swasthy watch" />

<Slider/>
<ParallaxProvider>

<WatchFeatureSec/>
<AboutSec/>
<ParalaxSec />



<SpecSec/>
<HelpSec/>
<GallerySec/>
<GalleryImg />

<Team/>
<Blogs/>
<ClientSec/>
<ContactSec/>

</ParallaxProvider>

  </Layout>
)

export default IndexPage
