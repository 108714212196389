import React, { Component } from 'react';
import styled from 'styled-components'
import FeatureContent from './featureSecCont'
import simg1 from '../../../images/slideimages/slide (1).jpg'

class WatchSlideSec extends Component {


    render() {
       
    return (
       

        <WatchSlideWrap className="container  " style={{overflow:"hidden"}}>

<div className="row">

    <div className="col-sm-12">

<FeatureContent/>


    </div>

     




</div>
  

  
        </WatchSlideWrap>
        
      );
    }
}


const WatchSlideWrap = styled.div`
ul{

    display:flex;

    li {

    width:35px;
    height:35px;
    border-radius:50%;
    margin:20px 15px;
    list-style:none;
    cursor: pointer;
    transition:0.2s;
    &:hover{

        transform:scale(1.2);
    }

    &:focus{

        transform:scale(1.2);
     box-shadow:0px 2px 5px 1px  grey;
        outline:none;

    }
    

} 
}

#pinContainer {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .watchimg{

      border-radius:15px;
      box-shadow:1px 7px 10px 1px grey;
  }


`
export default WatchSlideSec;