import React from 'react'
import styled from 'styled-components'
export default function Loading() {
    return (
        <LoadingWrap className="container-fluid" height="1000px">
            
            <div className="loader">

            <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div>

        </LoadingWrap>
    )
}

const LoadingWrap = styled.div`

position:relative;

.loader{


    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);

    .lds-facebook{

        background-color:blue;
    }
}

`