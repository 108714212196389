import styled from 'styled-components'

import React, { Component } from 'react';

import FeatureCont2 from './featuresec2'
import Heading from '../../../global/heading'


class FeatureSecCont extends Component {


 
    render() {

    return (
        
       <FeatureContentWrap className="container-fluid" id="features">

<div className="row">
    <div className="col-sm-10 py-4 py-sm-0">
<Heading hcolor="text-secondary " title="HOW IT WORKS"/>



    </div>

</div>


<div className="row mt-4">
    <div className="col-sm-12">
<p className="text-secondary">

"Svasthya our Bio-informatics platform for remote health discovery & delivery measures bio-vitals real-time and brings the doctor, patient and family members on a collaborative platform for viewing data from anywhere in the world. Svasthya also monitors the vitals continuously & generates alerts to doctors for appropriate health response based on derangement thresholds set by the doctor. The health/emergency response generated by “Svasthya' enables automated lifesaving and optimal real time care co-ordination.
<br/>
Svasthya enables doctors to look at the history of vitals to derive trends & treatment efficacy. Patient context assessment allows user to record the discrete symptoms and context based vitals during day to day life."</p>
    </div>
</div>

<div className="container-fluid">


<FeatureCont2/>
   

</div>

       </FeatureContentWrap>
    );
}
};



const FeatureContentWrap = styled.div`

h2{

    color:#111111;
    font-weight:200;
    font-size:2.8rem;
}
.custom-btn{

    color:grey;
    border:2px solid grey;
    background-color:transparent;
    padding:10px 40px;
    text-transform:uppercase;
}

p{

    font-size:15px;
    line-height:2;
    font-weight:400;
    font-family:var(--TextFont);
}


`


export default FeatureSecCont;



